import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { useState, useEffect, useReducer } from 'react';
export default (function (ref) {
    var _useState = useState(false), expandable = _useState[0], setExpandable = _useState[1];
    useEffect(function () {
        var checkExpandable = function checkExpandable() {
            if (ref.current) {
                var _ref$current = ref.current, clientHeight = _ref$current.clientHeight, scrollHeight = _ref$current.scrollHeight;
                setExpandable(scrollHeight > clientHeight);
            }
        };
        window.addEventListener('resize', checkExpandable);
        checkExpandable();
        return function () {
            return window.removeEventListener('resize', checkExpandable);
        };
    }, []);
    return [expandable].concat(_toConsumableArray(useReducer(function (state) {
        return !state;
    }, false)));
});
